
import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import annotationPlugin from "chartjs-plugin-annotation";
import { Card } from "primereact/card";
import { Spinner } from "reactstrap";
import { addMonths, subMonths } from "date-fns";
import CloudChartTooltip from "./CloudChartTooltip";
import BaseButton from "../../../../Components/Common/Buttons/BaseButton";
import { useTranslation } from "react-i18next";

Chart.register(annotationPlugin);
Chart.defaults.animations = false;

const CloudChart = ({ data, loading }) => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(() => {
    const currentDate = new Date();
    return subMonths(currentDate, 4);
  });
  const [endDate, setEndDate] = useState(() => {
    const currentDate = new Date();
    return addMonths(currentDate, 9);
  });
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipDetails, setTooltipDetails] = useState({});
  const [isLoadingTooltipDetails, setIsLoadingTooltipDetails] = useState(false);
  const [noData, setNoData] = useState(false);

  const handlePreviousMonth = () => {
    setStartDate((prevDate) => subMonths(prevDate, 1));
    setEndDate((prevDate) => subMonths(prevDate, 1));
  };

  const handleNextMonth = () => {
    setStartDate((prevDate) => addMonths(prevDate, 1));
    setEndDate((prevDate) => addMonths(prevDate, 1));
  };

  const createChart = (chartData) => {
    if (!chartData || chartData.length === 0 || !canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) {

      return;
    }

    const generateDataArray = () => {
      if (!chartData) {
        return [];
      }

      return chartData.map((item) => ({
        x: new Date(item?.date),
        y: parseInt(item?.priority.substring(1)),
        ...item,
      }));
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "time",
          time: {
            unit: "month",
            displayFormats: {
              month: "MMMM",
            },
          },
          min: startDate,
          max: endDate,
          grid: {
            color: "white",
          },
          ticks: {
            padding: 0,
            color: "black",
            font: {
              size: 14,
              family: ["Campton"],
              weight: "normal",
            },
            align: "center",
          },
        },
        y: {
          type: "linear",
          position: "left",
          beginAtZero: true,
          max: 12,
          min: -1,
          reverse: true,
          grid: {
            color: "white",
          },
          ticks: {
            stepSize: 1,
            padding: 20,
            min: -100,
            color: "black",
            font: {
              size: 14,
              family: ["Campton"],
              weight: "normal",
            },
            callback: (value) => ([12, -1].includes(value) ? "" : `P${value}`),
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: false,
          external: function (context) {
            const { chart, tooltip } = context;
            const position = chart.canvas.getBoundingClientRect();

            if (tooltip.opacity === 0) {
              setTooltipData(null);
              return;
            }

            if (tooltip.body) {
              const dataPoint = tooltip.dataPoints[0].raw;
              const tooltipHeight = 222;
              setTooltipData({
                ...dataPoint,
                position: {
                  left: position.left + window.pageXOffset + tooltip.caretX,
                  top: position.top + window.pageYOffset + tooltip.caretY - tooltipHeight - 10,
                },
              });
            }
          },
        },
        legend: {
          display: false,
        },
        scaleBackgroundColor: {
          color: "#e9ecef",
        },
        annotation: {
          drawTime: "afterDatasetsDraw",
          annotations: {
            verticalLine: {
              type: "line",
              mode: "vertical",
              scaleID: "x",
              value: Date.now(),
              borderColor: "red",
              borderWidth: 3,
              label: {
                display: true,
                content: "Today",
                position: "start",
                backgroundColor: "red",
                font: {
                  size: 16,
                  family: ["Monospace"],
                },
              },
            },
          },
        },
      },
    };

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(ctx, {
      type: "scatter",
      data: {
        datasets: [
          {
            label: "ET",
            content: "ET",
            data: generateDataArray(),
            backgroundColor: "#1D447F",
            borderColor: "black",
            pointRadius: 15,
            hoverRadius: 16,
            pointStyle: function (context) {
              const point = context.raw;
              const ctx = context.chart.ctx;
              const pointSize = context.raw.pointRadius || 15;

              ctx.save();
              ctx.beginPath();
              ctx.arc(point.x, point.y, pointSize, 0, Math.PI * 2, true);
              ctx.closePath();
              ctx.fillStyle = "white";
              ctx.strokeStyle = "black";
              ctx.lineWidth = 2;
              ctx.fill();
              ctx.stroke();

              ctx.restore();

              return true;
            },
          },
        ],
      },
      options: options,
      plugins: [
        {
          id: "scaleBackgroundColor",
          beforeDraw: (chart, args, opts) => {
            const {
              ctx,
              chartArea: { left, top, bottom },
            } = chart;

            const todayXCoordinate = chartRef.current.scales.x.getPixelForValue(Date.now());
            const monthWidth = chartRef.current.scales.x.getPixelForValue(new Date().setMonth(new Date().getMonth() + 1)) - todayXCoordinate;
            const oneMonthBeforePosition = chartRef.current.scales.x.getPixelForValue(new Date().setMonth(new Date().getMonth() - 1));
            const twoMonthsBeforePosition = chartRef.current.scales.x.getPixelForValue(new Date().setMonth(new Date().getMonth() - 2));
            const threeMonthsBeforePosition = chartRef.current.scales.x.getPixelForValue(new Date().setMonth(new Date().getMonth() - 7));

            ctx.beginPath();
            ctx.rect(left, top, 1850, bottom - top);
            ctx.fillStyle = opts.color || "white";
            ctx.fill();

            ctx.beginPath();
            ctx.rect(oneMonthBeforePosition, top + 1, monthWidth, bottom - top - 1);
            ctx.fillStyle = "#FFD700";
            ctx.fill();

            ctx.beginPath();
            ctx.rect(twoMonthsBeforePosition, top + 1, monthWidth, bottom - top - 1);
            ctx.fillStyle = "#FFA500";
            ctx.fill();

            ctx.beginPath();
            ctx.rect(threeMonthsBeforePosition, top + 1, 5 * monthWidth, bottom - top - 1);
            ctx.fillStyle = "#FF3333";
            ctx.fill();

            ctx.beginPath();
            ctx.rect(left, top, -100, bottom + top);
            ctx.fillStyle = "#ffffff";
            ctx.fill();
          },
        },
      ],
    });
  };

  const clearChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setNoData(false);
      createChart(data);
    } else {
      setNoData(true);
      clearChart();
    }
  }, [data, startDate, endDate]);

  if (loading) {
    return <Spinner className="m-auto mt-5 table-chart-spinner">Loading...</Spinner>;
  }

  return (
    <Card>
      <div style={{ height: "700px", width: "100%", position: "relative" }}>
        <canvas ref={canvasRef} id="myScatterChart"></canvas>
        {noData && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)"
            }}
          >
            <div className="m-auto">{t("SMART_ORDERS_NO_RESULT")}</div>
          </div>
        )}
        {tooltipData && (
          <CloudChartTooltip
            {...tooltipData}
            tooltipDetails={tooltipDetails}
            setTooltipDetails={setTooltipDetails}
            isLoadingTooltipDetails={isLoadingTooltipDetails}
            setIsLoadingTooltipDetails={setIsLoadingTooltipDetails}
            dataKey={tooltipData.key}
          />
        )}
      </div>
      <div className="d-flex justify-content-between mt-3" style={{ marginLeft: 72 }}>
        <BaseButton icon="leftArrow" text="Previous Month" onClick={handlePreviousMonth} disabled={noData} />
        <BaseButton icon="rightArrow" text="Next Month" iconPosition="right" onClick={handleNextMonth} disabled={noData} />
      </div>
    </Card>
  );
};

export default CloudChart;

